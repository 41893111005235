/* The scrollbar itself */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0px;
    transition: background-color 0.3s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
